import React from "react"
import { graphql } from "gatsby"
import Container from "../components/layout/Container"
import Header from "../components/Header"
import Space from "../components/Space"
import Content from "../components/layout/Content"
import styled from "@emotion/styled"
import ButtonBack from "../components/ButtonBack"
import { Helmet } from "react-helmet"
import { SEO_KEYWORDS, SEO_DESCRIPTION } from "../utils"
import TrainingBanner from "../components/TrainingBanner"

const Title = styled.h1`
    text-align: center;
`

const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    margin-bottom: 4rem;
    div:first-of-type {
        margin-right: 1rem;
    }
`

const Text = styled.div`
    max-width: 900px;
    line-height: 30px;
    .images > p {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    img {
        max-width: 400px;
        display: block;
        margin-bottom: 1rem;
        @media (max-width: 420px) {
            max-width: initial;
            width: 90%;
        }
    }
`

const Center = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export default props => {
    const { edges } = props.data.allMarkdownRemark
    const edge = edges[0]
    return (
        <Container>
            <Helmet>
                <title>{edge.node.frontmatter.title}</title>
                <meta
                    name="keywords"
                    content={edge.node.frontmatter.keywords || SEO_KEYWORDS}
                />
                <meta
                    name="description"
                    content={
                        edge.node.frontmatter.description || SEO_DESCRIPTION
                    }
                />
            </Helmet>
            <Header />
            <TrainingBanner/>
            <Space />
            <Content>
                <TitleWrapper>
                    <ButtonBack backUrl="/video">{"<"}</ButtonBack>
                    <Title>{edge.node.frontmatter.title}</Title>
                </TitleWrapper>

                <Center>
                    <Text
                        dangerouslySetInnerHTML={{ __html: edge.node.html }}
                    />
                </Center>
            </Content>
            <Space />
        </Container>
    )
}

export const articleOneQuery = graphql`
    query singleArticleQuery($id: String!) {
        allMarkdownRemark(filter: { id: { eq: $id } }) {
            edges {
                node {
                    html
                    frontmatter {
                        title
                        keywords
                        description
                    }
                }
            }
        }
    }
`
