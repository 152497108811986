import React from "react"
import styled from "@emotion/styled"
import bannerImg from "../assets/img/banner1.jpg"
import { Link } from "gatsby"
import { css } from "@emotion/core"

const Banner = styled.div`
    position: relative;
    height: 150px;
    background-image: url(${bannerImg});
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
`

const Title = styled.div`
    font-weight: 400;
    font-size: 3em;
    font-family: "Yanone Kaffeesatz";
    color: white;
    user-select: none;
    -webkit-text-stroke: 2px black;
    text-align: center;
`

const Bg = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background-image: linear-gradient(
        90deg,
        rgba(55, 124, 158, 0.7) 0%,
        rgba(216, 137, 218, 0.7) 100%
    );
    transition: background-image 1s ease-in-out;
    &:hover {
        background-image: linear-gradient(
            90deg,
            rgba(55, 124, 158, 0.9) 0%,
            rgba(216, 137, 218, 0.9) 100%
        );
        & > div {
            -webkit-text-stroke: 1px black;
        }
    }
`

export default () => {
    return (
        <Link
            onClick={() => {
                if (window.ym) {
                    window.ym(516565, "reachGoal", "BANNER_TOP_CLICK")
                }
            }}
            css={css`
                margin-top: 0.5rem;
            `}
            to="/training"
        >
            <Banner>
                <Bg>
                    <Title>Попробуй курс по прическам</Title>
                </Bg>
            </Banner>
        </Link>
    )
}
