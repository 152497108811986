import React from "react"
import styled from "@emotion/styled"

const ButtonBack = styled.div`
    display: inline-block;
    box-sizing: border-box;
    color: black;
    padding: 1rem;
    cursor: pointer;
    &:hover {
        color: white;
        background-color: #377c9e;
    }
`

export default ({ children, backUrl = "" }) => {
    const handleClick = React.useCallback(() => {
        if (window.history) {
            if (window.history.length > 2) {
                window.history.back()
            } else {
                window.location = backUrl
            }
        }
    })

    return (
        <ButtonBack role="button" onClick={handleClick}>
            {children}
        </ButtonBack>
    )
}
